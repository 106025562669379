import type { UserApiKeyPreview } from '../types';
import { crudGenerator } from './atom-creators';

export const {
    deleteItem: deleteUserAPIKey,
    createItem: createUserAPIKey,
    updateItem: updateUserAPIKey,
    dataAtom: userAPIKeysAtom,
    statusAtom: userAPIKeysFetchStatusAtom,
    fetchData: fetchUserApiKeys,
    dataLoadedAtom: userAPIKeysLoadedAtom,
} = crudGenerator<UserApiKeyPreview, { name: string }>('/auth/api-keys', 'API Key');
