/**
 * These functions create atoms that are computed from other atoms, so that we
 * don't re-create the same derived data values over and over again.
 */

import { PrimitiveAtom, atom, Atom } from 'jotai';
import { globalStore } from './globalStore';
import { FetchStatus } from '@/types';
import { client } from '@/services/HTTPClient';
import { trackAmplitudeEvent } from '@/instrumentation/amplitude';
import axios from 'axios';

type CreateGetPointReturn<T> = {
    dataAtom: PrimitiveAtom<T | null>;
    statusAtom: PrimitiveAtom<FetchStatus>;
    dataLoadedAtom: Atom<boolean>;
    fetchData: () => Promise<T | null>;
};

export function createGetEndpoint<T>(path: string): CreateGetPointReturn<T> {
    const dataAtom = atom<T | null>(null);
    const statusAtom = atom<FetchStatus>('init');
    const dataLoadedAtom = atom<boolean>((get) => ['success', 'refreshing'].includes(get(statusAtom)));

    function fetchData() {
        globalStore.set(statusAtom, globalStore.get(statusAtom) === 'success' ? 'refreshing' : 'loading');
        return client
            .get<T>(path)
            .then(({ data }) => {
                globalStore.set(dataAtom, data);
                globalStore.set(statusAtom, 'success');
                return data;
            })
            .catch((e) => {
                if (!axios.isAxiosError(e) || !axios.isCancel(e)) {
                    globalStore.set(statusAtom, 'error');
                }
                return null;
            });
    }

    return { dataAtom, statusAtom, dataLoadedAtom, fetchData };
}

function filterItemsWithSameId<T extends { id: string }>(items: T[] | null, id: string): T[] {
    if (!items) {
        return [];
    }
    return items.filter((item) => item.id !== id);
}

// TODO Convert all resource stores into using this

export function crudGenerator<ResponseType extends { id: string }, CreateType, UpdateType = CreateType>(path: string, amplitudeEventLabel?: string) {
    const { dataAtom, statusAtom, dataLoadedAtom, fetchData } = createGetEndpoint<ResponseType[]>(path);

    function createItem(item: CreateType) {
        return client.post<ResponseType>(path, item).then(({ data }) => {
            globalStore.set(dataAtom, (items) => [...filterItemsWithSameId(items, data.id), data]);

            if (amplitudeEventLabel) {
                trackAmplitudeEvent(`${amplitudeEventLabel} Created`, { id: data.id });
            }

            return data;
        });
    }

    function updateItem(itemId: string, item: UpdateType) {
        return client.put<ResponseType>(`${path}/${itemId}`, item).then(({ data }) => {
            console.log({
                id: data.id,
                itemId,
            });

            globalStore.set(dataAtom, (currentItems) => {
                return [...filterItemsWithSameId(currentItems, data.id), data];
            });

            if (amplitudeEventLabel) {
                trackAmplitudeEvent(`${amplitudeEventLabel} Updated`, { id: data.id });
            }

            return data;
        });
    }

    function deleteItem(itemId: string) {
        return client.delete(`${path}/${itemId}`).then(() => {
            if (amplitudeEventLabel) {
                trackAmplitudeEvent(`${amplitudeEventLabel} Deleted`, { id: itemId });
            }

            globalStore.set(dataAtom, (items) => filterItemsWithSameId(items, itemId));
        });
    }

    function fetchItem(itemId: string, signal?: AbortSignal) {
        return client.get<ResponseType>(`${path}/${itemId}`, { signal }).then(({ data }) => {
            globalStore.set(dataAtom, (items) => [...filterItemsWithSameId(items, data.id), data]);
            return data;
        });
    }

    return {
        dataAtom,
        statusAtom,
        dataLoadedAtom,
        fetchItem,
        fetchData,
        createItem,
        updateItem,
        deleteItem,
    };
}
