import { Link, Navigate } from 'react-router-dom';
import { Auth } from '@/components/Auth/Auth';
import { SignInForm } from '@/components/Auth/SignInForm';
import { QuiText } from '@tonicai/ui-quinine';
import { useAtomValue } from 'jotai';
import { signedInAtom } from '@/stores/auth';
import { SSOButtons } from '@/components/Auth/SSOButtons';

export function SignIn() {
    const signedIn = useAtomValue(signedInAtom);

    if (signedIn) {
        return <Navigate to="/" />;
    }

    return (
        <Auth>
            <QuiText size="display-xs" weight="medium">
                Sign In
            </QuiText>
            <SSOButtons prefix="Sign In With" />
            <SignInForm />
            <QuiText className="qui-text-align-center" as="p">
                Need an account? <Link to="/signup">Sign Up</Link>
            </QuiText>
            <QuiText className="qui-text-align-center" as="p">
                Forgot your password? <Link to="/forgot-password">Reset password</Link>
            </QuiText>
        </Auth>
    );
}
