import { atomWithStorage } from 'jotai/utils';
import {
    EnabledSSOConfig,
    JWT,
    MessageResponse,
    OAuth2PasswordRequestForm,
    SSOCallbackData,
    SSOErrorData,
    SSOSignInConfig,
    SsoProvider,
    Token,
    UserCreate,
    UserResponse,
} from '../types';
import { client } from '../services/HTTPClient';
import { FORM_ERROR } from 'final-form';
import { globalStore } from './globalStore';
import { atom } from 'jotai';
import { jwtDecode } from 'jwt-decode';
import { appURL, isValidAccessTokenResponse, isValidJWT } from '../utils';
import { hubspot } from '@/instrumentation/hubspot';
import { fullStoryAnonymize, fullStoryIdentify } from '../instrumentation/fullstory';
import { initAmplitude, resetAmplitudeUser } from '@/instrumentation/amplitude';
import { gtag } from '@/instrumentation/gtag';

const ACCESS_TOKEN_STORAGE_KEY = 'pyragorous.auth-state';

export const checkedAuthStateAtom = atom(false);

export const accessTokenAtom = atomWithStorage<Token | null>(ACCESS_TOKEN_STORAGE_KEY, getAccessTokenFromLocalStorage());

export const userAtom = atom<UserResponse | null>(null);

export const jwtTokenAtom = atom((get) => {
    const accessToken = get(accessTokenAtom);

    if (accessToken === null) return null;

    return jwtDecode(accessToken.access_token);
});

export const userIdAtom = atom((get) => {
    const accessToken = get(accessTokenAtom);

    if (accessToken === null) return null;

    return jwtDecode<JWT>(accessToken.access_token).sub;
});

export const signedInAtom = atom((get) => {
    return get(accessTokenAtom) !== null;
});

export const verifiedUserAtom = atom((get) => {
    return get(userAtom)?.verified ?? false;
});

export function signOut() {
    globalStore.set(accessTokenAtom, null);
    fullStoryAnonymize();
    globalStore.set(userAtom, null);
    globalStore.set(checkedAuthStateAtom, true);
}

export function signUp(userCreate: UserCreate) {
    return client
        .post<UserResponse>('/users', userCreate)
        .then(() => {
            gtag.trackSignup();
            return undefined;
        })
        .catch(() => {
            return {
                [FORM_ERROR]: 'Something went wrong. If you signed up with SSO, please use that instead.',
            };
        });
}

export function verifyUser(token: string) {
    return client.post<MessageResponse>(`/users/verification/${token}`).then(() => {
        fetchUser();
        return undefined;
    });
}

export function resetPassword(token: string, password: string) {
    return client
        .post<MessageResponse>(`/users/reset-password/`, {
            token: token,
            password: password,
        })
        .then(() => {
            fetchUser();
            return undefined;
        });
}

export function sendResetEmail(email: string) {
    return client.post<MessageResponse>(`/users/password-recovery/${email}`).then(() => {
        return undefined;
    });
}

export function sendVerificationEmail(email: string) {
    return client.post<MessageResponse>(`/users/verificationEmail/${email}`).then(() => {
        return undefined;
    });
}

export function signIn(data: OAuth2PasswordRequestForm) {
    const params = new URLSearchParams();
    params.append('username', data.username);
    params.append('password', data.password);
    return client
        .post<Token>('/auth/access-token', params)
        .then(({ data }) => {
            setAccessToken(data);
        })
        .catch((e) => {
            console.error(e);
            return {
                [FORM_ERROR]: 'Something went wrong. If you signed up with SSO, please use that instead.',
            };
        });
}

function fetchUser() {
    const userId = globalStore.get(userIdAtom);

    if (!userId) return;

    return client
        .get<UserResponse>(`/users`)
        .then(({ data }) => {
            globalStore.set(userAtom, data);
            globalStore.set(checkedAuthStateAtom, true);
            fullStoryIdentify(data.id, {
                email: data.email,
            });
            hubspot.identifyUser(data.email);
        })
        .catch(() => {
            globalStore.set(userAtom, null);
            globalStore.set(checkedAuthStateAtom, true);
            fullStoryAnonymize();
        });
}

export function initAuth() {
    const userId = globalStore.get(userIdAtom);

    if (userId === null) {
        globalStore.set(checkedAuthStateAtom, true);
        return;
    }

    return fetchUser();
}

function removeAccessTokenFromLocalStorage() {
    window.localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
}

function getAccessTokenFromLocalStorage() {
    const value = window.localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

    if (typeof value !== 'string') return null;

    try {
        const parsed = JSON.parse(value);
        if (!isValidAccessTokenResponse(parsed)) {
            throw new Error('Invalid AccessTokenResponse');
        }

        const decoded = jwtDecode<JWT>(value);
        if (!isValidJWT(decoded)) {
            throw new Error('Invalid JWT');
        }

        const now = new Date().valueOf();
        if (decoded.exp * 1000 < now) {
            resetAmplitudeUser();
            throw new Error('Token expired');
        }

        if (parsed.amplitude_api_key && decoded.sub) {
            initAmplitude(parsed.amplitude_api_key, decoded.sub);
        }

        return parsed;
    } catch {
        removeAccessTokenFromLocalStorage();
        return null;
    }
}

function setAccessToken(authResponse: Token | null) {
    const value = isValidAccessTokenResponse(authResponse) ? authResponse : null;

    if (value) {
        const decoded = jwtDecode<JWT>(value.access_token);
        if (!isValidJWT(decoded)) {
            resetAmplitudeUser();
            throw new Error('Invalid JWT');
        }
        if (value.amplitude_api_key && decoded.sub) {
            initAmplitude(value.amplitude_api_key, decoded.sub);
        }
    } else {
        resetAmplitudeUser();
    }
    globalStore.set(accessTokenAtom, value);
}

export const ssoCallbackDataAtom = atom<SSOCallbackData | SSOErrorData | null>(null);

export function setSSOCallbackData(data: SSOCallbackData | SSOErrorData | null) {
    globalStore.set(ssoCallbackDataAtom, data);
}

export async function ssoSignIn(code: string, provider: SsoProvider) {
    const postData: SSOSignInConfig = {
        code,
        provider,
        redirect_url: appURL(`/sso/callback/${provider.toLowerCase()}`),
    };
    const { data } = await client.post<Token>('/auth/sso/login', postData);

    const value = isValidAccessTokenResponse(data) ? data : null;

    if (value?.is_new_user === true) {
        gtag.trackSignup();
    }

    setAccessToken(data);
}

function getEnabledSSOConfigs() {
    // Create a new EnabledSSOConfig
    const configs: EnabledSSOConfig[] = [];
    // Add Google SSO only if the client ID is set
    const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
    if (googleClientId) {
        configs.push({
            provider: SsoProvider.google,
            clientId: googleClientId,
        });
    }
    // Add GitHub SSO only if the client ID is set
    const githubClientId = import.meta.env.VITE_GITHUB_CLIENT_ID;
    if (githubClientId) {
        configs.push({
            provider: SsoProvider.github,
            clientId: githubClientId,
        });
    }
    return configs;
}

export const enabledSSOConfigsAtom = atom(getEnabledSSOConfigs());
