import isPlainObject from 'is-plain-obj';
import { Token, FetchStatus, JWT, RunChartResponse, BarChartData, HistogramChartData, XYChartData, SsoProvider } from '../types';
import { FORM_ERROR } from 'final-form';

export function noop() {
    return;
}

export function getLoadingFetchStatus(fetchStatus: FetchStatus): FetchStatus {
    return fetchStatus === 'success' ? 'refreshing' : 'loading';
}

export function appURL(pathname: string) {
    const u = new URL(window.location.toString());
    u.hash = '';
    u.search = '';
    u.pathname = pathname;
    return u.toString();
}

export function parseDate(d: string | undefined | null): Date | null {
    if (d === undefined || d === null) return null;

    try {
        return new Date(d);
    } catch {
        return null;
    }
}

const dateTimeFormatter = new Intl.DateTimeFormat(navigator.language, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
});

export function formatDateTime(d: Date) {
    return dateTimeFormatter.format(d);
}

export function urlSearchParamsToObject(p: URLSearchParams) {
    const values: Record<string, string | null> = {};

    p.forEach((value, key) => {
        values[key] = value;
    });

    return values;
}

export function isValidAccessTokenResponse(v: unknown): v is Token {
    if (!isPlainObject(v)) return false;

    if (!('access_token' in v) || typeof v.access_token !== 'string') return false;

    if (!hasStringValueInObject('access_token', v)) return false;

    if (!hasOptionalStringValueInObject('user_created_at', v)) return false;

    return hasStringValueInObject('token_type', v) && v.token_type === 'bearer';
}

export function isValidJWT(v: unknown): v is JWT {
    if (!isPlainObject(v)) return false;

    if (!hasNumberValueInObject('exp', v)) return false;

    return hasStringValueInObject('sub', v);
}

export function isFormError(v: unknown): v is { [FORM_ERROR]: string } {
    if (!isPlainObject(v)) return false;

    return FORM_ERROR in v && typeof v[FORM_ERROR] === 'string';
}

export function hasStringValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r && typeof r[key] === 'string';
}

export function hasOptionalStringValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r ? typeof r[key] === 'string' : true;
}

export function hasNumberValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r && typeof r[key] === 'number';
}

export function hasOptionalNumberValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r ? typeof r[key] === 'number' : true;
}

export function hasOptionalBooleanValueInObject(key: string, r: Record<string | number | symbol, unknown>) {
    return key in r ? typeof r[key] === 'boolean' : true;
}

export function isURL(maybeURL: unknown) {
    if (typeof maybeURL !== 'string') return false;

    try {
        new URL(maybeURL);
        return true;
    } catch {
        return false;
    }
}

export function cleanUrl(url: unknown, maxLength: number = 20, keepLast: number = 7) {
    if (typeof url !== 'string') return url;
    const urlObject = new URL(url);

    // Remove protocol from the URL
    const cleanedUrl = urlObject.href.replace(`${urlObject.protocol}//`, '');
    const mainPart = cleanedUrl.length > maxLength ? cleanedUrl.substring(0, maxLength - keepLast - 3) : cleanedUrl;
    const lastPart = cleanedUrl.substring(cleanedUrl.length - keepLast);
    return cleanedUrl.length > maxLength ? `${mainPart}...${lastPart}` : cleanedUrl;
}

export function isXYChartData(chartData: RunChartResponse['chart_data']): chartData is XYChartData {
    if (!('x-axis' in chartData) || !Array.isArray(chartData['x-axis']) || !chartData['x-axis'].every((v) => typeof v === 'number')) return false;
    return 'y-axis' in chartData && Array.isArray(chartData['y-axis']) && chartData['y-axis'].every((v) => typeof v === 'number');
}

export function isXYDateChartData(chartData: RunChartResponse['chart_data']): chartData is XYChartData {
    if (!('x-axis' in chartData) || !Array.isArray(chartData['x-axis']) || !chartData['x-axis'].every((v) => typeof v === 'string')) return false;
    return 'y-axis' in chartData && Array.isArray(chartData['y-axis']) && chartData['y-axis'].every((v) => typeof v === 'number');
}

export function isBarChartData(chartResponse: Pick<RunChartResponse, 'chart_type' | 'chart_data'>): chartResponse is BarChartData {
    if (chartResponse.chart_type !== 'bar') return false;
    return isXYChartData(chartResponse.chart_data);
}

export function isHistogramChartData(chartResponse: Pick<RunChartResponse, 'chart_type' | 'chart_data'>): chartResponse is HistogramChartData {
    if (chartResponse.chart_type !== 'histogram') return false;
    if (!('histogram_bin_width' in chartResponse.chart_data)) return false;
    return isXYChartData(chartResponse.chart_data);
}
export function isLineChartData(chartResponse: Pick<RunChartResponse, 'chart_type' | 'chart_data'>): chartResponse is HistogramChartData {
    if (chartResponse.chart_type !== 'line') return false;
    return isXYDateChartData(chartResponse.chart_data);
}

export function toPercentage(decimal?: number) {
    if (decimal === undefined) return 'N/A';
    return `${Math.round(decimal * 100)}%`;
}

export function toRoundedNumber(decimal?: number) {
    if (decimal === undefined) return 'N/A';
    return `${Math.round(decimal * 100) / 100}`;
}

export function getSSOURL(provider: SsoProvider, clientId: string) {
    // GitHub
    if (provider === SsoProvider.github) {
        const githubURL = new URL('https://github.com/login/oauth/authorize');
        githubURL.searchParams.set('redirect_uri', appURL('/sso/callback/github'));
        githubURL.searchParams.set('scope', 'user:email,read:user');
        githubURL.searchParams.set('client_id', clientId);
        return githubURL.toString();
    }

    // Google
    if (provider === SsoProvider.google) {
        const googleURL = new URL('https://accounts.google.com/o/oauth2/auth');
        googleURL.searchParams.set('response_type', 'code');
        googleURL.searchParams.set('redirect_uri', appURL('/sso/callback/google'));
        googleURL.searchParams.set('scope', 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile');
        googleURL.searchParams.set('client_id', clientId);
        return googleURL.toString();
    }

    // everything else is not supported
    return null;
}

export function isSSOProvider(p: unknown): p is SsoProvider {
    return (
        typeof p === 'string' &&
        Object.entries(SsoProvider)
            .map(([v]) => v)
            .includes(p)
    );
}

/**
 * Run an async function on each item in an array sequentially (rather than
 * in parallel, like in: Promise.all(items.map(action))
 *
 * @param items Array of items to iterate over
 * @param action A function to run on each item
 */
export async function asyncForEachSeries<T>(items: T[], action: (item: T) => Promise<unknown>) {
    for (const item of items) {
        await action(item);
    }
}

export function cleanUpStatsName(name: string) {
    // Replace _ with space
    name = name.replace(/_/g, ' ');
    // Capitalize letters after space
    let words = name.split(' ');
    words = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    name = words.join(' ');
    return name;
}

export function maxScore(stat: string) {
    if (stat == 'answer_similarity') {
        return 5;
    }
    return 1;
}
