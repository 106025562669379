import { useAtomValue } from 'jotai';
import { ProjectRequest, ProjectResponse } from '../types';
import { crudGenerator } from './atom-creators';

export const {
    dataAtom: projectsAtom,
    statusAtom: projectsStatusAtom,
    dataLoadedAtom: projectsLoadedAtom,
    fetchData: fetchProjects,
    createItem: createProject,
    deleteItem: deleteProject,
    updateItem: updateProject,
    fetchItem: fetchProject,
} = crudGenerator<ProjectResponse, ProjectRequest>('/projects', 'Project');

export function useProjects() {
    return useAtomValue(projectsAtom);
}

export function useProject(projectId: string | null) {
    const projects = useAtomValue(projectsAtom);

    if (!projectId) return null;

    return projects?.find((b) => b.id === projectId) ?? null;
}
