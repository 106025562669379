import { useAtomValue } from 'jotai';
import { verifiedUserAtom } from '../../stores/auth';
import { Navigate, Outlet } from 'react-router-dom';

export function VerifiedGuard() {
    const verified = useAtomValue(verifiedUserAtom);
    if (!verified) return <Navigate to="/unverified" />;

    return <Outlet />;
}
