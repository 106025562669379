import { FORM_ERROR } from 'final-form';

/**
 * HTTP
 */

export type FetchStatus = 'init' | 'loading' | 'error' | 'success' | 'refreshing' | 'accepted';

/**
 * Globals
 */
export type ChartType = 'bar' | 'histogram' | 'line';

/**
 * Forms
 */
export type FormError = {
    [FORM_ERROR]: string;
};

/**
 * Auth
 */

export type UserResponse = {
    id: string;
    email: string;
    verified: boolean;
};

export type MessageResponse = {
    msg: string;
};

export type UserCreate = {
    email: string;
    password: string;
};

export type UserForgot = {
    email: string;
};

export type UserReset = {
    password: string;
    retypedPassword: string;
};

export type OAuth2PasswordRequestForm = {
    username: string;
    password: string;
};

export type Token = {
    access_token: string;
    token_type: string;
    amplitude_api_key?: string;
    is_new_user?: boolean;
};

export type JWT = {
    exp: number;
    sub: string;
};

export type JSONPrimitive = string | null | number | boolean;

/**
 * Benchmarks
 */

export type BenchmarkRequest = {
    name: string;
};

export type BenchmarkResponse = {
    id: string;
    name: string;
};

export type BenchmarkItemRequest = {
    question: string;
    answer: string | null;
};

export type BenchmarkItemResponse = {
    id: string;
    question: string;
    answer: string | null;
    benchmark_id: string;
};

/**
 * Projects
 */

export type ProjectRequest = {
    name: string;
    monitoring: boolean;
};

export type ProjectResponse = {
    id: string;
    name: string;
    monitoring: boolean;
};

/**
 * Runs
 */

export type RunMetadata = { [key: string]: JSONPrimitive | JSONPrimitive[] };

export type RunResponse = {
    id: string;
    overall_scores: Record<string, number | null>;
    created_at: string;
    updated_at: string;
    run_metadata: RunMetadata;
    tags: string[];
};

export type RunLogResponse = {
    id: string;
    scores: Record<string, number | null>;
    reference_question: string;
    reference_answer: string | null;
    llm_answer: string;
    llm_context: string[] | null;
    created_at: string;
    updated_at: string;
};

// Enum for increase, decrease, same
export enum Change {
    Increase = 'Increase',
    Decrease = 'Decrease',
    Same = 'Same',
    NA = 'N/A',
}

export type ProjectTableResponse = {
    changes: Record<string, (number | null)[]>;
    titles: string[];
    chart_metric: string;
};

export type RunLogRequest = {
    scores: Record<string, number | null>;
    reference_question: string;
    reference_answer: string;
    llm_answer: string;
    llm_context: string[];
};

export type RunLogPreviewResponse = {
    id: string;
    scores: Record<string, number | null>;
    llm_answer: string;
    reference_question: string;
    reference_answer: string;
    run_id: string;
};

/**
 * Monitoring
 */

export type MonitoringLogResponse = {
    id: string;
    project_id: string;
    scores: Record<string, number | null>;
    reference_question: string;
    llm_answer: string;
    llm_context: string[] | null;
    log_metadata: RunMetadata;
    tags: string[];
    created_at: string;
    updated_at: string;
};

export type MonitoringGraphResponse = {
    interval_minutes: number;
    time: string;
    scores: Record<string, number | null>;
};

/**
 * Validation
 */

export type ValidationError = {
    loc: (string | number)[];
    msg: string;
    type: string;
};

export type HTTPValidationError = {
    detail?: ValidationError[];
};

// Run Charts
export type RunChartCreate = {
    chart_type: ChartType;
    chart_metric: string;
};

export type RunChartResponse = {
    id: string;
    run_id: string;
    chart_data: Record<string, number | number[] | Record<string, string>>;
    chart_type: string;
    chart_metric: string;
    created_at: string;
    updated_at: string;
    run_metadata: Record<string, JSONPrimitive>;
};

// Project Charts

export type ProjectChartRequest = {
    chart_type: ChartType;
    chart_metric: string;
};

export type ProjectChartResponse = {
    id: string;
    project_id: string;
    chart_data: Record<string, number | number[]>;
    chart_type: string;
    chart_metric: string;
    run_metadata: Record<string, JSONPrimitive>[];
};

export type XYChartData = {
    'x-axis': number[];
    'y-axis': number[];
    histogram_bin_width?: number;
};

export type XYDateChartData = {
    'x-axis': string[];
    'y-axis': number[];
};

export type HistogramChartData = {
    chart_data: XYChartData;
    chart_type: 'histogram';
};

export type BarChartData = {
    chart_data: XYChartData;
    chart_type: 'bar';
};

export type LineChartData = {
    chart_data: XYChartData;
    chart_type: 'line';
};

/**
 * User API Keys
 */
export type UserApiKey = {
    id: string;
    name: string;
    preview: string;
    created_at: string;
    key: string;
    autogenerated_key: boolean;
};

export type UserApiKeyPreview = {
    id: string;
    name: string;
    preview: string;
    created_at: string;
    autogenerated_key: boolean;
};

/**
 * SSO
 */

export enum SsoProvider {
    google = 'Google',
    github = 'Github',
}

export type SSOCallbackData = {
    code: string;
    provider: SsoProvider;
    type: 'code';
};

export type SSOErrorData = {
    error: string | null;
    errorDescription: string | null;
    type: 'error';
};

export type SSOSignInConfig = {
    code: string;
    provider: string;
    redirect_url: string;
};

export type EnabledSSOConfig = {
    provider: SsoProvider;
    clientId: string;
};
