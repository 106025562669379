import { PrimitiveAtom, atom, Atom } from 'jotai';
import { globalStore } from './globalStore';
import { FetchStatus } from '../types';
import { client } from '../services/HTTPClient';

export function createGetEndpoint<T>(path: string): [PrimitiveAtom<T | null>, PrimitiveAtom<FetchStatus>, Atom<boolean>, () => Promise<T | null>] {
    const dataAtom = atom<T | null>(null);
    const statusAtom = atom<FetchStatus>('init');
    const dataLoadedAtom = atom<boolean>((get) => ['success', 'refreshing'].includes(get(statusAtom)));

    function fetchData() {
        globalStore.set(statusAtom, globalStore.get(statusAtom) === 'success' ? 'refreshing' : 'loading');
        return client
            .get<T>(path)
            .then(({ data }) => {
                globalStore.set(dataAtom, data);
                globalStore.set(statusAtom, 'success');
                return data;
            })
            .catch(() => {
                globalStore.set(statusAtom, 'error');
                return null;
            });
    }

    return [dataAtom, statusAtom, dataLoadedAtom, fetchData];
}
