import { lazy, useEffect } from 'react';
import { useAtomValue } from 'jotai';
import { Route, Routes } from 'react-router-dom';
import { useQuiToasts } from '@tonicai/ui-quinine';

import { AuthGuard } from '../AuthGuard/AuthGuard';
import { AppLayout } from '../AppLayout/AppLayout';
import { FullScreenLoading } from '../FullScreenLoading/FullScreenLoading';
import {
    checkedAuthStateAtom,
    initAuth,
    setSSOCallbackData,
    signOut,
    signedInAtom,
    ssoCallbackDataAtom,
    ssoSignIn,
} from '../../stores/auth';
import { fetchBenchmarks } from '../../stores/benchmarks';
import { fetchProjects } from '../../stores/projects';
import { VerifiedGuard } from '../VerifiedGuard/VerifiedGuard';
import { hubspot } from '../../instrumentation/hubspot';

import { SignUp } from '@/pages/SignUp/SignUp';
import { SignIn } from '@/pages/SignIn/SignIn';
import { Unverified } from '@/pages/Unverified/Unverified';
import { Verify } from '@/pages/Verify/Verify';
import { ForgotPassword } from '@/pages/ForgotPassword/ForgotPassword';
import { ResetPassword } from '@/pages/ResetPassword/ResetPassword';
import { SSOCallback } from '@/pages/SSOCallback/SSOCallback';
import { NotFound } from '@/pages/NotFound/NotFound';

// Lazy load the pages that require authentication, so that signing in is
// snappy, and the user doesn't have to wait for all the pages to load on
// their first visit.
const Home = lazy(() => import('@/pages/Home/Home'));
const Benchmarks = lazy(() => import('@/pages/Benchmarks/Benchmarks'));
const Project = lazy(() => import('@/pages/Project/Project'));
const ProjectRun = lazy(() => import('@/pages/Project/ProjectRun'));
 

export function App() {
    const checkedAuthState = useAtomValue(checkedAuthStateAtom);
    const isSignedIn = useAtomValue(signedInAtom);
    const ssoCallbackData = useAtomValue(ssoCallbackDataAtom);
    const addToast = useQuiToasts();

    useEffect(() => {
        // Init hubspot as soon as the app is mounted.
        hubspot.init();
    }, []);

    useEffect(() => {
        initAuth();
    }, []);

    useEffect(() => {
        if (!isSignedIn) return;

        fetchBenchmarks();
        fetchProjects();
    }, [isSignedIn]);

    useEffect(() => {
        if (ssoCallbackData === null) return;

        if (ssoCallbackData.type === 'error') return;

        ssoSignIn(ssoCallbackData.code, ssoCallbackData.provider)
            .catch(() => {
                addToast({
                    title: 'Can not log in with this SSO provider. Please use the SSO provider or email/password you used to sign up.',
                    variant: 'warning',
                });
                signOut();
            })
            .finally(() => {
                setSSOCallbackData(null);
            });
    }, [ssoCallbackData, addToast]);

    if (!checkedAuthState) {
        return <FullScreenLoading />;
    }

    return (
        <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/sso/callback/:ssoProvider" element={<SSOCallback />} />
            <Route element={<AuthGuard />}>
                <Route path="/unverified" element={<Unverified />} />
                <Route path="/verify" element={<Verify />} />
                <Route element={<VerifiedGuard />}>
                    <Route element={<AppLayout />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/benchmarks" element={<Benchmarks />} />
                        <Route path="/projects/:projectId" element={<Project />}>
                            <Route path="/projects/:projectId/runs/:runId" element={<ProjectRun />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}
