import { QuiButton, QuiFlexV, QuiForm, QuiPasswordField, QuiTextField } from '@tonicai/ui-quinine';
import { signIn } from '../../stores/auth';
import { OAuth2PasswordRequestForm } from '../../types';
import { SubmitError } from './SubmitError';
import { requiredEmail, requiredString } from '../../validation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Message } from '../Message/Message';

export function SignInForm() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    return (
        <QuiForm<OAuth2PasswordRequestForm>
            onSubmit={(values: OAuth2PasswordRequestForm) => {
                return signIn(values).then((response) => {
                    if (response === undefined) {
                        navigate('/');
                    } else {
                        return response;
                    }
                });
            }}
        >
            <QuiFlexV gap="md">
                {searchParams.get('accountCreated') !== null && <Message variant="success">Account created! Please sign in.</Message>}
                <QuiTextField label="Email Address" validate={requiredEmail} name="username" />
                <QuiPasswordField validate={requiredString} hideShowPasswordToggle label="Password" name="password" />
                <SubmitError />
                <QuiButton variant="green">Sign In</QuiButton>
            </QuiFlexV>
        </QuiForm>
    );
}
