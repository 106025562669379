import { QuiBox, QuiIcon } from '@tonicai/ui-quinine';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import styles from './TextualBanner.module.scss';

const lastDismissedAtAtom = atomWithStorage('lastDismissedAt', -1);
const oneWeek = 1000 * 60 * 60 * 24 * 7;

export function TextualBanner() {
    const [lastDismissedAt, setLastDismissedAt] = useAtom(lastDismissedAtAtom);

    // Current dateTime in epoch time
    const now = new Date().getTime();

    if (lastDismissedAt !== -1 && now - lastDismissedAt < oneWeek
    ) {
        return null;
    }

    return (
        <QuiBox display="flex" className={styles.wrapper} position="relative" bg="green-300" color="white" flexDirection="row" alignItems="center">
            <QuiBox position="relative" maxWidth="wide" margin="none auto" text="text-xs" padding="sm md" textAlign="center">
                <a className={styles.link} href="https://www.tonic.ai/textual?utm_campaign=Validate%20Referral&utm_source=Validate%20Referral">
                    <strong>Boost your RAG performance</strong> by preparing your unstructured data for RAG with Tonic Textual.
                    Extract, parse, and chunk text seamlessly, detect and redact sensitive info, and enrich vector stores with document and entity metadata.
                    Try it for free <u>here</u>
                </a>
            </QuiBox>
            <button
                className={styles.closeButton}
                type="button"
                onClick={() => {
                    setLastDismissedAt(now);
                }}
            >
                <QuiIcon icon="x" />
            </button>
        </QuiBox>
    );
}
