import { Auth } from '@/components/Auth/Auth';
import { QuiButton, QuiFlexV, QuiForm, QuiTextField, useQuiToasts } from '@tonicai/ui-quinine';
import { UserForgot } from '@/types';
import { sendResetEmail } from '@/stores/auth';
import { SubmitError } from '@/components/Auth/SubmitError';
import { requiredEmail } from '@/validation';

export function ForgotPassword() {
    const addToast = useQuiToasts();
    return (
        <Auth>
            <QuiForm<UserForgot>
                onSubmit={(values: UserForgot) => {
                    sendResetEmail(values.email);
                    addToast({
                        variant: 'positive',
                        title: 'Please check your email for a password reset link.',
                    });
                }}
            >
                <QuiFlexV gap="md">
                    <QuiTextField label="Email Address *" validate={requiredEmail} name="email" />
                    <SubmitError />
                    <QuiButton variant="green">Send password reset email</QuiButton>
                </QuiFlexV>
            </QuiForm>
        </Auth>
    );
}
