import { Navigate, useSearchParams } from 'react-router-dom';
import { Auth } from '@/components/Auth/Auth';
import { QuiText } from '@tonicai/ui-quinine';
import { useAtomValue } from 'jotai';
import { verifiedUserAtom, verifyUser } from '@/stores/auth';
import { useEffect } from 'react';

export function Verify() {
    const verified = useAtomValue(verifiedUserAtom);
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');

    useEffect(() => {
        if (token != null) {
            verifyUser(token);
        }
    }, [token]);

    if (verified) {
        return <Navigate to="/" />;
    }

    const text = token != null ? (!verified ? 'Verifying your account...' : 'Your account is now verified') : 'Invalid verification token';

    return (
        <Auth>
            <QuiText size="text-sm" className="qui-text-align-center" weight="medium">
                {text}
            </QuiText>
        </Auth>
    );
}
