import axios from 'axios';
import { globalStore } from '../stores/globalStore';
import { accessTokenAtom } from '../stores/auth';

export const client = axios.create({
    withCredentials: true,
});

// Add access token to request (if it exists) & set baseURL
client.interceptors.request.use((config) => {
    const authResponse = globalStore.get(accessTokenAtom);

    config.baseURL = import.meta.env.VITE_API_BASE_URL;

    if (authResponse) {
        config.headers.set('Authorization', `${authResponse.token_type} ${authResponse.access_token}`);
    }

    return config;
});
