import { Outlet } from 'react-router-dom';
import {
    QuiMenu,
    QuiMenuItem,
    QuiMenuItemBox,
    QuiMenuItems,
    QuiNav,
    QuiNavAvatarMenuButton,
    QuiNavButton,
    QuiNavRight,
    QuiBox,
} from '@tonicai/ui-quinine';
import { CurrentUserAvatar } from '../CurrentUserAvatar/CurrentUserAvatar';
import { useAtomValue } from 'jotai';
import { signOut, userAtom } from '@/stores/auth';
import { Suspense, useEffect, useState } from 'react';
import { fetchProjects } from '@/stores/projects';
import { fetchUserApiKeys } from '@/stores/userApiKeys';
import { useIsMounted } from '@/hooks/useIsMounted';
import { PageLoading } from '../PageLoading/PageLoading';
import { TextualBanner } from '../TextualBanner/TextualBanner';

export function AppLayout() {
    const user = useAtomValue(userAtom);
    const isMounted = useIsMounted();
    const [loadingDone, setLoadingDone] = useState(false);

    useEffect(() => {
        Promise.all([fetchProjects(), fetchUserApiKeys()]).then(() => {
            if (!isMounted()) return;
            setLoadingDone(true);
        });
    }, [isMounted]);

    if (!loadingDone) {
        return <PageLoading />;
    }

    return (
        <Suspense fallback={<PageLoading />}>
            <TextualBanner />
            <QuiNav fluid logo="validate" variant="green">
                <QuiNavButton to="/" iconLeft="home">
                    Home
                </QuiNavButton>

                <QuiNavButton to="/benchmarks" iconLeft="activity">
                    Benchmarks
                </QuiNavButton>

                <QuiNavRight>

                    <QuiMenu placement="bottom-end">
                        <QuiNavAvatarMenuButton />

                        <QuiMenuItems>
                            <QuiMenuItemBox>
                                <QuiBox padding="md" alignItems="center" display="flex" gap="sm">
                                    <CurrentUserAvatar />
                                    <div>{user?.email}</div>
                                </QuiBox>
                            </QuiMenuItemBox>
                            <QuiMenuItem iconLeft={'log-out'} onClick={signOut}>
                                Sign Out
                            </QuiMenuItem>
                        </QuiMenuItems>
                    </QuiMenu>
                </QuiNavRight>
            </QuiNav>
            <main>
                <QuiBox maxWidth="desktop" padding="lg" margin="none auto">
                    <Outlet />
                </QuiBox>
            </main>
        </Suspense>
    );
}
