import { QuiBox, QuiButton, QuiFlexV, QuiForm, QuiLink, QuiPasswordField, QuiTextField } from '@tonicai/ui-quinine';
import { UserCreate } from '../../types';
import { signUp } from '../../stores/auth';
import { SubmitError } from './SubmitError';
import { requiredEmail, requiredPassword } from '../../validation';
import { useNavigate } from 'react-router-dom';

export function SignUpForm() {
    const navigate = useNavigate();

    return (
        <QuiForm<UserCreate>
            onSubmit={(values: UserCreate) => {
                return signUp(values).then((response) => {
                    if (response === undefined) {
                        navigate('/signin?accountCreated=true');
                    } else {
                        return response;
                    }
                });
            }}
        >
            <QuiFlexV gap="md">
                <QuiTextField label="Email Address" validate={requiredEmail} name="email" />
                <QuiPasswordField validate={requiredPassword} hideShowPasswordToggle label="Password" name="password" />
                <SubmitError />
                <QuiBox text="text-xs">
                    Signing up signifies that you have read and agree to our{' '}
                    <QuiLink external to="http://tonic.ai/terms/free-trial">
                        Terms of Use
                    </QuiLink>{' '}
                    and <QuiLink external to="https://www.tonic.ai/privacy-policy">{`Privacy Policy`}</QuiLink>, unless there is a signed agreement
                    between you and Tonic, which you agree will govern this use.
                </QuiBox>
                <QuiButton variant="green">Sign Up</QuiButton>
            </QuiFlexV>
        </QuiForm>
    );
}
