import { useAtomValue } from 'jotai';
import { signedInAtom } from '../../stores/auth';
import { Navigate, Outlet } from 'react-router-dom';

export function AuthGuard() {
    const signedIn = useAtomValue(signedInAtom);

    if (!signedIn) return <Navigate to="/signin" />;

    return <Outlet />;
}
