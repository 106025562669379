const hubspotEnabled = window.location.hostname === 'validate.tonic.ai' || sessionStorage.getItem('validate.hosted-tracking-test') !== null;
// for testing locally uncomment the line below and comment out the line above
// const hubspotEnabled = true;

// const _hsq = (window._hsq = window._hsq ?? []);
const scriptId = 'hs-script-loader';

let hubspotInitialized = false;

function identifyUser(email: string) {
    if (!hubspotEnabled || !hubspotInitialized) return;

    if (window._hsq) {
        window._hsq.push(['identify', { email }]);
    }
}

function init() {
    if (!hubspotEnabled) return;

    // Add Hubspot tracking script on hosted only
    if (
        hubspotInitialized === false &&
        document.getElementById(scriptId) === null // redundant but just in case
    ) {
        const hubspotScriptTag = document.createElement('script');
        hubspotScriptTag.id = scriptId;
        hubspotScriptTag.async = true;
        hubspotScriptTag.defer = true;
        hubspotScriptTag.src = '//js.hs-scripts.com/4809962.js';
        document.body.appendChild(hubspotScriptTag);
        hubspotInitialized = true;
    }
}

export const hubspot = {
    init,
    identifyUser,
};
