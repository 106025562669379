import { QuiBox, QuiFlexV } from '@tonicai/ui-quinine';
import { ReactNode } from 'react';
import styles from './Auth.module.scss';
import TonicValidateLogo from '../icons/tonic-validate-light.svg?react';
import { Link } from 'react-router-dom';

type AuthProps = {
    children: ReactNode;
};

export function Auth({ children }: AuthProps) {
    return (
        <div className={styles.auth}>
            <Link to="/">
                <QuiBox display="flex" alignItems="center" justifyContent="center" padding="lg" gap="sm" text="display-xs">
                    <TonicValidateLogo height={22} />
                </QuiBox>
            </Link>
            <QuiBox bg="white" padding="md" borderRadius="lg" border="white-600">
                <QuiFlexV gap="md" padding="md">
                    {children}
                </QuiFlexV>
            </QuiBox>
        </div>
    );
}
