import { ReactNode } from 'react';
import { QuiBox, QuiSpinner } from '@tonicai/ui-quinine';
import TonicValidateLogo from '../icons/tonic-validate-light.svg?react';

type FullScreenLoadingProps = Readonly<{
    children?: ReactNode;
}>;

export function FullScreenLoading({ children }: FullScreenLoadingProps) {
    return (
        <QuiBox display="flex" flexDirection="column" minHeight="100vh" alignItems="center" justifyContent="center">
            <QuiBox display="flex" flexDirection="column" gap="md">
                <QuiBox display="flex" gap="sm" alignItems="center" justifyContent="center">
                    <TonicValidateLogo height={32} />
                </QuiBox>
                <QuiBox display="flex" justifyContent="center">
                    <QuiSpinner size="lg" />
                </QuiBox>
            </QuiBox>
            {children ? <QuiBox>{children}</QuiBox> : null}
        </QuiBox>
    );
}
