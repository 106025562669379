import { Navigate, useNavigate } from 'react-router-dom';
import { Auth } from '@/components/Auth/Auth';
import { QuiButton, QuiText, useQuiToasts } from '@tonicai/ui-quinine';
import { useAtomValue } from 'jotai';
import { initAuth, sendVerificationEmail, signOut, userAtom, verifiedUserAtom } from '@/stores/auth';
import { useEffect } from 'react';

export function Unverified() {
    const user = useAtomValue(userAtom);
    const verified = useAtomValue(verifiedUserAtom);
    const navigate = useNavigate();
    const addToast = useQuiToasts();

    useEffect(() => {
        initAuth();
    }, []);

    if (verified) {
        return <Navigate to="/" />;
    }

    return (
        <Auth>
            <QuiText size="text-sm" className="qui-text-align-center">
                Please check your email to verify your account
            </QuiText>
            <QuiButton
                variant="green"
                onClick={() => {
                    if (user != null) sendVerificationEmail(user.email);
                    addToast({
                        title: 'Verification email sent',
                        variant: 'positive',
                    });
                }}
            >
                Resend verification email
            </QuiButton>
            <QuiButton
                onClick={() => {
                    signOut();
                    navigate('/');
                }}
            >
                Log out
            </QuiButton>
        </Auth>
    );
}
