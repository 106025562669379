import { useFormState } from 'react-final-form';
import { Message } from '../Message/Message';

export function SubmitError() {
    const formState = useFormState();

    if (!formState.submitError) return null;

    return <Message variant="error">{formState.submitError}</Message>;
}
