import { client } from '../services/HTTPClient';
import { BenchmarkRequest, BenchmarkResponse } from '../types';
import { globalStore } from './globalStore';
import { createGetEndpoint } from './createGetEndpoint';
import { useAtomValue } from 'jotai';
import { trackAmplitudeEvent } from '@/instrumentation/amplitude';

export const [benchmarksAtom, benchmarksStatusAtom, benchmarksLoadedAtom, fetchBenchmarks] = createGetEndpoint<BenchmarkResponse[]>('/benchmarks/');

export function fetchBenchmark(benchmarkId: string) {
    return client
        .get<BenchmarkResponse>(`/benchmarks/${benchmarkId}`)
        .then(({ data }) => {
            globalStore.set(benchmarksAtom, (benchmarks) => [...(benchmarks?.filter((b) => b.id !== data.id) ?? []), data]);
            return data;
        })
        .catch(console.error);
}

export function createBenchmark(data: BenchmarkRequest) {
    return client.post<BenchmarkResponse>('/benchmarks', data).then(({ data }) => {
        trackAmplitudeEvent('Benchmark Created');
        globalStore.set(benchmarksAtom, (benchmarks) => [...(benchmarks?.filter((b) => b.id !== data.id) ?? []), data]);
        return data;
    });
}

export function updateBenchmark(benchmarkId: string, data: BenchmarkRequest) {
    return client.put<BenchmarkResponse>(`/benchmarks/${benchmarkId}`, data).then(({ data }) => {
        trackAmplitudeEvent('Benchmark Updated', { benchmarkId });
        globalStore.set(benchmarksAtom, (benchmarks) => [...(benchmarks?.filter((b) => b.id !== data.id) ?? []), data]);
        return data;
    });
}

export function deleteBenchmark(benchmarkId: string) {
    return client.delete(`/benchmarks/${benchmarkId}`).then(() => {
        trackAmplitudeEvent('Benchmark Deleted', { benchmarkId });
        fetchBenchmarks();
    });
}

// Hooks

export function useBenchmark(benchmarkId: string | null) {
    const benchmarks = useAtomValue(benchmarksAtom);

    if (!benchmarkId) return null;

    return benchmarks?.find((b) => b.id === benchmarkId) ?? null;
}

export function useBenchmarks() {
    return useAtomValue(benchmarksAtom);
}
