import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './components/App/App';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// Tonic Validate & Quinine
import './scss/index.scss';
import { QuiToastsProvider } from '@tonicai/ui-quinine';

dayjs.extend(relativeTime);

Sentry.init({
    // only enable Sentry in production
    enabled: import.meta.env.PROD,

    // Hard-coded value, but doesn't matter because this will be public anyway. One less env var to setup.
    dsn: 'https://1757c845f7b52140825ced6bf6d13964@o4506077836541952.ingest.sentry.io/4506711887118336',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
            maskAllInputs: true,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/validate\.tonic\.ai\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <BrowserRouter>
            <QuiToastsProvider>
                <App />
            </QuiToastsProvider>
        </BrowserRouter>
    </React.StrictMode>
);
