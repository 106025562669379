import { useAtomValue } from 'jotai';
import { Or } from './Or';
import { getSSOURL } from '../../utils';
import GitHubLogo from '../icons/github.svg?react';
import GoogleLogo from '../icons/google.svg?react';
import { SsoProvider } from '../../types';
import styles from './SSOButtons.module.scss';
import { enabledSSOConfigsAtom } from '@/stores/auth';
import { QuiBox } from '@tonicai/ui-quinine';

const SSOIcons: Record<
    SsoProvider,
    React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
            title?: string;
        }
    >
> = {
    [SsoProvider.github]: GitHubLogo,
    [SsoProvider.google]: GoogleLogo,
};

type SSOButtonsProps = Readonly<{
    prefix: string;
}>;

export function SSOButtons({ prefix }: SSOButtonsProps) {
    const enabledSSOConfigs = useAtomValue(enabledSSOConfigsAtom);

    if (enabledSSOConfigs.length == 0) return null;

    return (
        <>
            <QuiBox gap="sm" display="flex" flexDirection="column">
                {enabledSSOConfigs.map((ssoSetting) => {
                    const ssoURL = getSSOURL(ssoSetting.provider, ssoSetting.clientId);

                    if (!ssoURL) return null;

                    const Icon = SSOIcons[ssoSetting.provider];

                    return (
                        <QuiBox flexDirection="column" key={ssoSetting.provider} display="flex">
                            <a className={styles.buttonLink} href={ssoURL} key={ssoSetting.provider}>
                                <Icon />
                                <span>{`${prefix} ${ssoSetting.provider}`}</span>
                            </a>
                        </QuiBox>
                    );
                })}
            </QuiBox>
            <Or />
        </>
    );
}
