declare global {
    interface Window {
        gtag?: (command: 'get' | 'set' | 'config' | 'event' | 'consent', ...params: (string | Record<string, string>)[]) => void;
    }
}

// Track signup event in google tag manager
function trackSignup() {
    if (window?.gtag && typeof window.gtag === 'function') {
        window.gtag('event', 'sign_up');
    }
}

export const gtag = {
    trackSignup,
};
