import { Auth } from '@/components/Auth/Auth';
import { SubmitError } from '@/components/Auth/SubmitError';
import { resetPassword } from '@/stores/auth';
import { UserReset } from '@/types';
import { QuiButton, QuiFlexV, QuiForm, QuiPasswordField, useQuiToasts } from '@tonicai/ui-quinine';
import { requiredString } from '@/validation';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function ResetPassword() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();
    const addToast = useQuiToasts();

    return (
        <Auth>
            <QuiForm<UserReset>
                onSubmit={(values: UserReset) => {
                    if (token == null) {
                        addToast({ variant: 'destructive', title: 'Invalid reset token' });
                        return;
                    }
                    if (values.password !== values.retypedPassword) {
                        addToast({
                            variant: 'destructive',
                            title: 'Passwords do not match',
                        });
                    } else {
                        resetPassword(token, values.password);
                        navigate('/');
                    }
                }}
            >
                <QuiFlexV gap="md">
                    <QuiPasswordField validate={requiredString} hideShowPasswordToggle label="Password" name="password" />
                    <QuiPasswordField validate={requiredString} hideShowPasswordToggle label="Retype Password" name="retypedPassword" />
                    <SubmitError />
                    <QuiButton variant="green">Reset Password</QuiButton>
                </QuiFlexV>
            </QuiForm>
        </Auth>
    );
}
