import * as amplitude from '@amplitude/analytics-browser';

import { globalStore } from '../stores/globalStore';
import { accessTokenAtom } from '@/stores/auth';

// Ensure amplitude is enabled before using it in tracking functions (for an
// example, look at `trackPreviewFile` below)
function getAmplitude() {
    const apiKey = globalStore.get(accessTokenAtom)?.amplitude_api_key;

    if (apiKey) return amplitude;

    return null;
}

export function initAmplitude(apiKey: string, userId: string) {
    const logLevel = import.meta.env.DEV ? amplitude.Types.LogLevel.Debug : amplitude.Types.LogLevel.None;
    amplitude.init(apiKey, userId, {
        logLevel,
        defaultTracking: true,
    });
}

export function resetAmplitudeUser() {
    amplitude.reset();
}

export function trackAmplitudeEvent(eventName: string, eventProperties?: Record<string, unknown>) {
    getAmplitude()?.track(eventName, eventProperties);
}
